<template>
  <custom-dialog
    :visible.sync="visible"
    :title="title"
    @close="colseDialog"
    @submit="submit"
    :showEdit="true"
    width="50%"
  >
    <div class="edit-main">
      <el-form
        :inline="true"
        :model="editForm"
        class="demo-form-inline"
        label-width="90px"
      >
        <el-form-item label="所属项目">
          <el-select
            class="block-select"
            size="small"
            clearable
            filterable
            v-model="editForm.projId"
            placeholder="请选择"
            @change="changeProject"
          >
            <el-option
              v-for="item in projectList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择设备：">
          <el-select
            class="block-select"
            size="small"
            clearable
            filterable
            v-model="editForm.equipId"
            placeholder="请选择"
            @change="changeEquipType"
          >
            <el-option
              v-for="item in equipTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="操作类型：">
          <el-select
            v-model="editForm.region"
            placeholder="请选择"
            class="block-select"
            size="small"
            clearable
          >
            <el-option label="驾驶人" value="驾驶人"></el-option>
            <!-- <el-option label="区域二" value="beijing"></el-option> -->
          </el-select>
        </el-form-item>
        <el-form-item label="选择人员">
          <el-select
            class="block-select"
            size="small"
            clearable
            filterable
            v-model="editForm.operId"
            placeholder="请选择"
            @change="changeOpear"
          >
            <el-option
              v-for="item in opearList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="success" size="small" class="btn" @click="onSubmit"
            >绑定</el-button
          >
        </el-form-item>
      </el-form>

      <el-table :data="tableData" border style="width: 100%">
        <el-table-column
          type="selection"
          align="center"
          width="55"
        ></el-table-column>
        <el-table-column
          prop="operName"
          align="center"
          show-overflow-tooltip
          label="操作员姓名"
          width="180"
        >
        </el-table-column>
        <el-table-column
          prop="operSex"
          label="性别"
          align="center"
          show-overflow-tooltip
          width="80"
        >
        </el-table-column>
        <el-table-column
          prop="operMobile"
          align="center"
          show-overflow-tooltip
          label="电话"
        >
        </el-table-column>
        <el-table-column
          prop="operIdcard"
          align="center"
          show-overflow-tooltip
          label="身份证号"
        >
        </el-table-column>
        <el-table-column
          prop="endTime"
          align="center"
          show-overflow-tooltip
          label="有效期"
        >
        </el-table-column>
        <el-table-column label="操作" align="center" width="100">
          <template slot-scope="scope">
            <span @click="deleteItem(scope.row)" class="detail delete">
              <i class="el-icon-delete"></i>
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </custom-dialog>
</template>

<script>
import CustomDialog from "../../components/common/CustomDialog.vue";
import config from "../../api/config";
import basic from "../../api/modules/basic";

//   import {equipType} from "../../../config/dataStatus"
//   import versionMange from "../../../api/modules/versionMange";

export default {
  name: "driverEdit",
  components: { CustomDialog },
  props: ["visible", "title"],
  data() {
    return {
      editForm: {},
      rules: {
        built: [{ required: true, message: "请输入程序标识", trigger: "blur" }],
        type: [
          { required: true, message: "请选择版本类别", trigger: "change" },
        ],
        ver: [{ required: true, message: "请输入固件版本", trigger: "blur" }],
        url: [{ required: true, message: "请选择升级包", trigger: "change" }],
        sourcefile: [
          { required: true, message: "请输入升级文件名", trigger: "blur" },
        ],
      },
      uploadUrl: config.uploadUrl,
      equipTypeList: [],
      tableData: [],
      opearList: [],
      projectList: [],
      changeOperList: [],
      equipIds: "",
      // equipType: equipType
    };
  },
  mounted() {
    // this.getEquip();
    this.getProjectLists();
  },
  methods: {
    getProjectLists() {
      
      
      basic.GetProjectList().then((res) => {
       
        res.data.map((item) => {
          this.projectList.push({
            label: item.projName,
            value: item.projId,
          });
        });
      });
    },
    changeProject(val) {
      console.log('valxx',val);
      this.editForm.operId=''
      this.editForm.equipId=''
      this.getEquip(val);
      this.getOperatorLists(val)
    },
    colseDialog() {
      this.$emit("close");
    },
    getEquip(projId) {
      this.equipTypeList=[]
      basic.getEquipList({projId:projId}).then((res) => {
        console.log("设备下拉框", res);
        res.data.map((item) => {
          this.equipTypeList.push({
            label: item.equipNo,
            value: item.equipId,
          });
        });
      });
    },
    getBingDingOperator(val) {
      basic.getBingDingOperatorList(val).then((res) => {
        this.tableData = res.data;
      });
    },
    getOperatorLists(project){
     
      this.opearList=[]
      basic.getOperatorList(project).then((res) => {
        console.log('resccc',res);
        
        this.changeOperList = res.data;
        res.data.map((item) => {
          this.opearList.push({
            label: item.operName,
            value: item.operId,
          });
        });
      });
    },
    changeEquipType(val) {
      console.log('val',val);
      
      // this.editForm.operId = "";
      this.tableData = [];
      this.equipIds = val;
   
      this.getBingDingOperator(this.equipIds);
     
    },
    changeOpear(val) {
      this.changeOperList.map((item) => {
        if (item.operId == val) {
          console.log("item", item);
          this.tableData.push({
            operName: item.operName,
            operSex: item.operSex,
            operMobile: item.operMobile,
            operIdcard: item.operIdcard,
            endTime: item.endTime,
            operId: item.operId,
            equipOperId: item.equipOperId,
          });
        }
      });
    },
    submit() {
      this.$refs.editVersionForm.validate((valid) => {
        if (valid) {
          versionMange.addDeviceVer(this.editForm).then((res) => {
            this.$message({
              message: "新增成功",
              type: "success",
            });
            this.$emit("done");
          });
        }
      });
    },
    onSubmit() {
      console.log("this.tableData", this.tableData);

      let params = {
        equipId: this.equipIds,
        baseOperatorEntityList: this.tableData,
      };
      basic.bingDing(params).then((res) => {
        this.$message({
          message: "绑定成功",
          type: "success",
        });
        this.getBingDingOperator(this.equipIds);
        this.$emit("done");
      });
    },
    deleteItem(item) {
      this.$confirm(`此操作将永久删除该人员, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          basic.delOperator(item.equipOperId).then((res) => {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.getBingDingOperator(this.equipIds);
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style scoped lang="less">
.editTitle {
  border: 1px solid #eeeeee;
  border-bottom: 0px solid #eeeeee;
  padding: 10px 0 10px 30px;
  border-radius: 6px 6px 0px 0px;
  font-weight: bold;
}
.edit-main {
  padding: 20px;
  border: 1px solid #eeeeee;
  border-radius: 0px 0px 6px 6px;
}
.btn {
  background-color: #008f4d;
  // color: #008f4d;
}
/deep/.el-dialog__body {
  padding: 30px 20px;
  color: #606266;
  font-size: 14px;
  word-break: break-all;
  padding-top: 0px !important;
}
.detail {
  color: #008f4d;
}
</style>
